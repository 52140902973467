import React from 'react';

import FieldList from './FieldList';

import { getCurrentResource, getDefinedValue, getDefinedItem } from '../../util/tools';

class FieldList_TableGroup extends FieldList {

	field = null;
	resource = null;

	/*
		cell
		table
			columns [{
				columnId
				title
				tdHeaderStyle
				tdStyle
			}]
			width
			styles
				table
				thead
				trHeader
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldList_TableGroup;
		this.init();
	}

	render() {
		if( ! this.field ) {
			return (
				<div>Unknown field</div>
			);
		}
		var styles = getDefinedValue( this.props.table.styles, {} );
		
		var headerHtml = this.createHeaderHtml(); // Label, buttons
		if( this.props.labelEmptyToHeader && this.field.value.length == 0 ) {
			var tableHtml = null;
		} else {
			var headerCellsHtml = this.props.table.columns.map((col) => {
				return (
					<td key={col.columnId} style={col.tdHeaderStyle}>
						{col.title}
					</td>
				)
			});
			
			var rowsHtml = this.field.value.map((itemField, index) => {
				var trStyle = {backgroundColor: 'lightGray'};
				if( index == this.state.selectedIndex )
					trStyle.backgroundColor = 'lightBlue';
				var cellsHtml = this.props.table.columns.map((col) => {
					var tdStyle = getDefinedValue(col.tdStyle, {});
					return (
						<td key={col.columnId} style={tdStyle}>
							<this.props.cell 
								instance={this.props.instance} columnId={col.columnId} rowIndex={index} 
								plain={this.props.plain} plainDirtyCounter={this.props.plainDirtyCounter} 
								fieldGroup={itemField}
							/>
						</td>
					)
				});
				return (
					<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(index)} }>
						{cellsHtml}
					</tr>
				)
			});
			
			var theadStyle = getDefinedValue(styles.thead, {textAlign: 'center', fontWeight: 'bold'});
			var trHeaderStyle = getDefinedValue(styles.trHeader, {height: '43px'});
			var tableStyle = {...getDefinedValue(styles.table, {})};
			if( ! tableStyle.width )
				tableStyle.width = '100%';
			tableHtml = (
				<table border='1' style={tableStyle}>
					<thead style={theadStyle}>
						<tr style={trHeaderStyle}>
							{headerCellsHtml}
						</tr>
					</thead>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			);
		}
		
		var divMainStyle = getDefinedValue(styles.divMain, {});
		return (
			<div style={divMainStyle}>
				{headerHtml}
				{tableHtml}
			</div>
		);
	}

}

export default FieldList_TableGroup;

import React from 'react';

class TabbedPane extends React.Component {

	/*
		tabs 
		divStyle
	*/
	constructor(props) {
		super(props);
		var tabRows = [];
		for(let i=props.tabs.length-1; i >= 0; i--) {
			tabRows.push( props.tabs[i] );
		}
		this.state = {
			tabRows: tabRows,
			activeTabIndex: 0
		}
	}

	setActiveTab = (rowId, tabIndex) => {
		if( rowId == this.state.tabRows[this.state.tabRows.length-1].rowId ) {
			this.setState({activeTabIndex: tabIndex});
			return;
		}
		
		var tabRows = [];
		var lastRow = null;
		for(let i=this.props.tabs.length-1; i >= 0; i--) {
			var row = this.props.tabs[i];
			if( row.rowId == rowId )
				lastRow = row;
			else
				tabRows.push( row );
		}
		tabRows.push( lastRow );
		this.setState({tabRows: tabRows, activeTabIndex: tabIndex});
	}
	
	render() {
		var tabRows = this.state.tabRows;
		var tabHtml = null;
		var divActivePanelHtml = null;
		if( tabRows.length > 0 ) {
			var activeRow = tabRows[ tabRows.length-1 ];
			tabHtml = tabRows.map((tabRow, rowIndex)=> {
				var tabRowHtml = tabRow.tabs.map( (tabItem, itemIndex) => {
					if( rowIndex == tabRows.length-1 && this.state.activeTabIndex == itemIndex )
						var style = localStyles.divActiveTab;
					else
						style = localStyles.divNotActiveTab;
					return (
						<div key={itemIndex} style={style} onClick={ ()=>{this.setActiveTab(tabRow.rowId, itemIndex)} }>
							{tabItem.title}
						</div>
					)
				} )
				return (
					<div key={tabRow.rowId} style={{display: 'flex', background: 'lightgray', padding: '5px 5px 0 5px'}}>
						{tabRowHtml}
					</div>
				);
			});
			divActivePanelHtml = (
				<div>
					{activeRow.tabs[ this.state.activeTabIndex ].component}
				</div>					
			)
		}
		return (
			<div style={this.props.divStyle}>
				{tabHtml}
				{divActivePanelHtml}
			</div>
		);
	}
}

const localStyles = {
	divActiveTab: {
		margin: '5px 0px 0 0px', /* top | right | bottom | left */
		padding: '5px 10px 5px 10px',
		fontWeight: 'bold',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: '#f8f8f8',
		cursor: 'pointer'
	},	
	divNotActiveTab: {
		margin: '5px 0px 0 0px',
		padding: '5px 10px 5px 10px',
		flexGrow: '1',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: 'lightgray',
		cursor: 'pointer',
		textAlign: 'center'
	},	
};

export default TabbedPane; 
import React from 'react';

import Label from '../../../components/plain/Label';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_Enum from '../../../components/plain/FieldList_Enum';
import FieldList_Strings from '../../../components/plain/FieldList_Strings';
import FieldList_MultilangString, {HEADER_POSITION_RIGHT} from '../../../components/plain/FieldList_MultilangString';
import FieldList_Reference from '../../../components/plain/FieldList_Reference';
import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';
import FieldList_CardsPanel, {DIRECTION_VERTICAL} from '../../../components/plain/FieldList_CardsPanel';

import {getLanguage} from '../../../util/localStorage';
import {getDefinedValue} from '../../../util/tools';
import { AUTHORITY_AUTHOR_PATH, TOPIC_PATH } from '../../../util/routes';
import { FORM_AUTHORITY_AUTHOR_TABLE, FORM_TOPIC_TABLE } from '../../../util/consts/forms';

class TabBrContent extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	resumeCardBuilder = (fieldResume, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var langText = plain.realm.getEnumerationItemText( plain.enumLanguage, fieldResume.value.language.value);
		if( langText )
			card += '[]'+ langText + '] ';
		
		card += getDefinedValue(fieldResume.value.text.value, '');
		
		if( card.length > 50 ) {
			card = card.substring(0, 47) + '...';
		}
		
		return card;
	} 
	fragmentCardBuilder = (fieldFragment, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var langText = plain.realm.getEnumerationItemText( plain.enumLanguage, fieldFragment.value.language.value);
		if( langText )
			card += '[]'+ langText + '] ';
		
		card += getDefinedValue(fieldFragment.value.text.value, '');
		
		if( card.length > 50 ) {
			card = card.substring(0, 47) + '...';
		}
		
		return card;
	} 
	aboutCardBuilder = (fieldAbout, index) => {
		var card = '';
		
		var currentLanguage = getLanguage();
		var name = null;
		for(let n of fieldAbout.value.name.value) {
			if( n.value.language.value == currentLanguage && 
					(n.value.shortName.value || n.value.fullName.value) ) 
			{
				name = n;
				break;
			}
		}
		if( name == null && fieldAbout.value.name.value.length > 0)
			name = fieldAbout.value.name.value[0];
		if( name ) {
			if( name.value.shortName.value )
				card += name.value.shortName.value;
			else if( name.value.fullName.value )
				card += name.value.fullName.value;
		}
		
		return card;
	} 
	
	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		var refAspectNodes = [
			{
				classId: 'Topic', plainFormPath: TOPIC_PATH, 
				selectInstanceFormId: FORM_TOPIC_TABLE,
				selectInstanceFormTitle: 'Теми'
			}
		];
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<FieldList_Enum title={'Мова видання'}
						instance={instance} path={'/content/language'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
					<FieldList_Enum title={'Мова оригіналу'}
						instance={instance} path={'/content/original'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
					<FieldList_Enum title={'Аудиторія'}
						instance={instance} path={'/content/audience'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<div style={{flexGrow: '1', width: '35%', marginRight: '10px'}}>
						<FieldList_TableGroup instance={instance} path={'/content/period'} 
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter}
							labelEmptyToHeader={true}
							title={'Період'} cell={PeriodCell}
							table={{
								columns: [
									{ columnId: 'timeFrom', title: 'Початок', tdHeaderStyle: {width: '25%'}},
									{ columnId: 'timeCodeFrom', title: 'Відлік початку', tdHeaderStyle: {width: '25%'}},
									{ columnId: 'timeUp', title: 'Закінчення', tdHeaderStyle: {width: '25%'}},
									{ columnId: 'timeCodeUp', title: 'Відлік закінчення', tdHeaderStyle: {width: '25%'}},
								],
								styles: {
									trHeader: {height: '60px'}
								}
							}} 
						/>
					</div>
					<div style={{flexGrow: '1', width: '55%', marginLeft: '10px'}}>
						<FieldList_TableGroup instance={instance} path={'/content/place'} 
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter}
							labelEmptyToHeader={true}
							title={'Місце'} cell={PlaceCell}
							table={{
								columns: [
									{ columnId: 'language', title: 'Мова', tdHeaderStyle: {width: '20%'}},
									{ columnId: 'country', title: 'Країна', tdHeaderStyle: {width: '20%'}},
									{ columnId: 'region', title: 'Регіон', tdHeaderStyle: {width: '20%'}},
									{ columnId: 'area', title: 'Область', tdHeaderStyle: {width: '20%'}},
									{ columnId: 'town', title: 'Місто', tdHeaderStyle: {width: '20%'}},
								],
								styles: {
									trHeader: {height: '60px'}
								}
							}} 
						/>
					</div>
				</div>`
				<div style={{display: 'flex', marginTop: '10px'}}>
					<div style={{flexGrow: '1', width: '100%'}}>
						<FieldList_TableGroup instance={instance} path={'/content/institution'} 
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter}
							labelEmptyToHeader={true}
							title={'Організація'} cell={InstitutionCell}
							table={{
								columns: [
									{ columnId: 'level', title: 'Рівень', tdHeaderStyle: {width: '20%'}, tdStyle: {verticalAlign: 'top'}},
									{ columnId: 'name', title: 'Назва', tdHeaderStyle: {width: '80%'}},
								]
							}} 
						/>
					</div>
				</div>`
				<FieldList_CardsPanel path={'/content/resume'} instance={instance} plain={plain} 
					itemComponent={ResumePanel} itemCardBuilder={this.resumeCardBuilder}
					labelEmptyToHeader={true}
					title={'Анотація'}
					styles={{
						divMainStyle: {
							marginTop: '10px',
							padding: '10px 10px 10px 10px',
							backgroundColor: 'rgb(200,200,200)',
							border: '1px solid blue'
						},
						divCardsBlock: {
							marginRight: '10px',
							minWidth: '400px',
							backgroundColor: 'rgb(200,200,200)',
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'rgb(230,230,230)',
							minHeight: '18px'
							
						}
					}}
				/>
				<FieldList_CardsPanel path={'/content/fragment'} instance={instance} plain={plain} 
					itemComponent={FragmentPanel} itemCardBuilder={this.fragmentCardBuilder}
					labelEmptyToHeader={true}
					title={'Фрагменти'}
					styles={{
						divMainStyle: {
							marginTop: '10px',
							padding: '10px 10px 10px 10px',
							backgroundColor: 'rgb(200,200,200)',
							border: '1px solid blue'
						},
						divCardsBlock: {
							marginRight: '10px',
							minWidth: '300px',
							backgroundColor: 'rgb(200,200,200)',
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'rgb(230,230,230)',
							minHeight: '18px'
							
						}
					}}
				/>
				<FieldList_CardsPanel path={'/content/about'} instance={instance} plain={plain} 
					itemComponent={AboutPanel} itemCardBuilder={this.aboutCardBuilder}
					labelEmptyToHeader={true}
					title={'Про'}
					styles={{
						divMainStyle: {
							marginTop: '10px',
							padding: '10px 10px 10px 10px',
							backgroundColor: 'rgb(200,200,200)',
							border: '1px solid blue'
						},
						divCardsBlock: {
							marginRight: '10px',
							minWidth: '300px',
							backgroundColor: 'rgb(200,200,200)',
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'rgb(230,230,230)',
							minHeight: '18px'
							
						}
					}}
				/>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<FieldList_Strings title={'Ключові слова'}
						instance={instance} path={'/content/keyword'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldList_Strings title={'Посилання'}
						instance={instance} path={'/content/reference'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1'}}}
					/>
				</div>
				<FieldList_Reference path={'/content/aspect'} instance={instance} plain={plain} 
					title='Аспекти змісту' styles={{divMain: {marginTop: '10px'}}}
					labelEmptyToHeader={true}
					refNodes={refAspectNodes}
				/>
			</div>
		)
	}
}

class PeriodCell extends React.Component {

	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'timeFrom' ) {
			return <FieldString instance={this.props.instance} path={'timeFrom'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'timeCodeFrom' ) {
			return <FieldString instance={this.props.instance} path={'timeCodeFrom'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'timeUp' ) {
			return <FieldString instance={this.props.instance} path={'timeUp'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'timeCodeUp' ) {
			return <FieldString instance={this.props.instance} path={'timeCodeUp'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		return null;
	}

}
class PlaceCell extends React.Component {

	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'language' ) {
			return <FieldEnum instance={this.props.instance} path={'language'} plain={plain} parentField={fieldGroup} styleSelect={{width: '95%'}} />
		}
		if( columnId == 'country' ) {
			return <FieldEnum instance={this.props.instance} path={'country'} plain={plain} parentField={fieldGroup} styleSelect={{width: '95%'}} />
		}
		if( columnId == 'region' ) {
			return <FieldString instance={this.props.instance} path={'region'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'area' ) {
			return <FieldString instance={this.props.instance} path={'area'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'town' ) {
			return <FieldString instance={this.props.instance} path={'timeCodeUp'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		return null;
	}

}
class InstitutionCell extends React.Component {

	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'level' ) {
			return <FieldString instance={this.props.instance} path={'level'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'name' ) {
			return (
				<FieldList_MultilangString
					instance={this.props.instance} path={'name'} parentField={fieldGroup} plain={plain}
					labelEmptyToHeader={true} styles={{divMain: {padding: '5px'}}}
					headerPosition={HEADER_POSITION_RIGHT}
				/>
			);
		}
		return null;
	}

}

class ResumePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Мова'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', marginRight: '20px'}}
						/>
						<Label title={'Формат'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'format'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<Label title={'Частина'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'part'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<Label title={'Текст'} style={{}}/>
					<FieldString instance={instance} path={'text'} parentField={fieldGroup} plain={plain}
						multiline={true} rows={4} cols={60}
						styleInput={{width: '98%'}}
					 />
					<div style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
						<Label title={'Ресурс'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'uri'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}} styleDiv={{width: '100%'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

class FragmentPanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Мова'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', marginRight: '20px'}}
						/>
						<Label title={'Формат'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'format'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<Label title={'Місце'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'place'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<Label title={'Зміст'} style={{}}/>
					<FieldString instance={instance} path={'text'} parentField={fieldGroup} plain={plain}
						multiline={true} rows={4} cols={60}
						styleInput={{width: '98%'}}
					 />
				</div>
			</div>
		)
	}
}

class AboutPanel extends React.Component {

	refAboutAuthorityNodes = [
		{
			classId: 'AuthorityAuthor', plainFormPath: AUTHORITY_AUTHOR_PATH, 
			selectInstanceFormId: FORM_AUTHORITY_AUTHOR_TABLE,
			selectInstanceFormTitle: 'Авторитетні записи'
		}
	];
	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	nameCardBuilder = (fieldName, index) => {
		var plain = this.props.plain;
		var card = '';
		var language = fieldName.value.language.value;
		if( language ) {
			var languageText = plain.realm.getEnumerationItemText( plain.enumLanguage, language);
			card += languageText + '; ';
		}
		if( fieldName.value.shortName.value )
			card += fieldName.value.shortName.value;
		else if( fieldName.value.fullName.value )
			card += fieldName.value.fullName.value;
		if( ! card )
			card = '...'
		return card;
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		var refSubjectAuthorityNodes = [
			{
				classId: 'AuthorityAuthor', plainFormPath: AUTHORITY_AUTHOR_PATH, 
				selectInstanceFormId: FORM_AUTHORITY_AUTHOR_TABLE,
				selectInstanceFormTitle: 'Авторитетні записи'
			}
		];
		console.log('Render AboutPanel', instance, fieldGroup)
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Роки життя'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'dates'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<FieldList_CardsPanel path={'name'} instance={instance} parentField={fieldGroup} plain={plain} 
						itemComponent={NamePanel} itemCardBuilder={this.nameCardBuilder}
						direction={DIRECTION_VERTICAL} title='Імена'
						styles={{
							divMainStyle: {
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue',
								marginBottom: '10px'
							},
							divCardsBlock: {
								backgroundColor: 'rgb(200,200,200)',
								minWidth: '300px'
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
							}
						}}
					/>
					<FieldList_Reference path={'subjectAuthority'} instance={instance} parentField={fieldGroup} plain={plain} 
						title='Авторитетні записи' styles={{divMain: {marginTop: '10px'}}}
						labelEmptyToHeader={true}
						refNodes={refSubjectAuthorityNodes}
					/>
				</div>
			</div>
		)
	}
}

class NamePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}

	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '5px 0 0 0', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Мова'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', marginRight: '20px'}}
						/>
						<Label title={'Роль (уточнення)'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'role'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '20px'}}
						 />
						<Label title={'Тип'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'nameType'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Прізвище або назва'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'keyName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '20px'}}
						 />
						<Label title={'Ім\'я'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'name'} parentField={fieldGroup} plain={plain}
							styleInput={{maxWidth: '170px', flexGrow: '1', marginRight: '20px'}}
						 />
						<Label title={'Друге ім\'я'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'secondName'} parentField={fieldGroup} plain={plain}
							styleInput={{maxWidth: '170px', flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Скорочене ім\ʼя'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'shortName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<Label title={'Повне ім\ʼя'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'fullName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Місце'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'place'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<Label title={'Афіліація'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'affilation'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Примітки'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrContent; 
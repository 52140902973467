import React from 'react';

import { getDefinedValue } from '../../util/tools';

class Field extends React.Component {

	field = null;
	resource = null;

	/*
		plain                      mandatory
		instance                   mandatory
		parentField                optional
		path                       mandatory
		readOnly
	*/
	constructor(props) {
		super(props);
		this.init();
		this.state = {
			readOnly: getDefinedValue( props.readOnly, false)
		}
	}
	componentDidUpdate(prevProps, prevState) {
		var doInit = false;
		var updateListener = ( prevProps.instance !== this.props.instance );
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		if ( doInit ) {
			this.init();
			this.setState({ value: getDefinedValue( this.field.value, '') });
		}
		if( updateListener ) {
			if( prevProps.instance )
				prevProps.instance.removeChangeListener( this );
			if( this.props.instance )
				this.props.instance.addChangeListener( this );
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
		this.completeComponentDidUpdate(prevProps, prevState, doInit);
		this.validateInit();
	}
	completeComponentDidUpdate(prevProps, prevState, doInit) {
		
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
		this.validateInit();
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init() {
		if( this.props.instance ) {
			if( this.props.parentField )
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			else
				this.field = this.props.instance.getField(this.props.path);
		}
	}
	validateInit() {
		if( ! this.field ) {
			console.log( 'Unknown field (instance, parentField, path)', 
					this.props.instance, this.props.parentField, this.props.path )
		}
	}
}

export default Field;

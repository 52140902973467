import React from 'react';
import stylesPlain from './plain.module.css';

import Field from './Field';
import Label from './Label'; 

import { getDefinedValue, getDefinedItem, getCurrentResource } from '../../util/tools';
import { appendListStyle } from '../../util/realm/styles';
import { 
	IET_SET_VALUE, IET_ADD_LIST_ITEM, IET_MOVE_UP_LIST_ITEM, 
	IET_MOVE_DOWN_LIST_ITEM, IET_DELETE_LIST_ITEM 
} from '../../util/realm/instance';

import iconAdd from '../../assets/icons/png24x24/Add.png';
import iconMoveUp from '../../assets/icons/png24x24/Up.png';
import iconMoveDown from '../../assets/icons/png24x24/Down.png';
import iconDelete from '../../assets/icons/png24x24/Delete.png';

class FieldList extends Field {

	resourceFieldList = null;
	
	/*
		useProposal
		disableMoveItems
		labelEmptyToHeader
		title
		tooltip
		header
		styles
			label
		table
			width
			styles
				table
	*/
	constructor(props) {
		super(props);
		this.resourceFieldList = getCurrentResource().components.plain.FieldList;
		if( this.field ) {
			this.state.value = getDefinedValue( this.field.value, []);
			this.state.selectedIndex = (this.field.value.length > 0) ? 0 :-1;
		}
	}
	completeComponentDidUpdate(prevProps, prevState, doInit) {
		if ( doInit ) {
			var index = this.state.selectedIndex;
			if( index >= this.field.value.length ) {
				index = this.field.value.length - 1;
				this.setState({ selectedIndex: index });
			}
			this.setState({ value: getDefinedValue( this.field.value, []) });
		}
	}
	init() {
		super.init();
		if( this.field ) {
			if( this.props.useProposal ) {
				var length = this.field.value.length;
				var addProposal = false;
				if( length == 0 ) {
					addProposal = true;
				} else {
					if( ! this.field.value[length-1].isProposal ) {
						if( this.isValueEmpty(length-1) )
							this.field.value[length-1].isProposal = true;
						else
							addProposal = true;
					}
				}
				if( addProposal ) {
					this.props.instance.addListItem(this.field);
					length++;
					this.field.value[length-1].isProposal = true;
				}
			}
		}
	}
	isValueEmpty(valueIndex) {
		return false;
	}

	async handleInstanceChange(event) {
		if( event.eventType == IET_SET_VALUE && event.field.parent == this.field) {
			var instance = this.props.instance;
			if( this.props.useProposal && event.field.isProposal ) {
				event.field.isProposal = false;
				var newItem = instance.addListItem(this.field);
				newItem.isProposal = true;
			}
			this.setState({value: this.field.value});
		} else if( event.eventType == IET_ADD_LIST_ITEM && event.field == this.field) {
			this.setState({	value: this.field.value });
		} else if( event.eventType == IET_MOVE_UP_LIST_ITEM && event.field == this.field) {
			this.setState({	value: this.field.value });
		} else if( event.eventType == IET_MOVE_DOWN_LIST_ITEM && event.field == this.field) {
			this.setState({	value: this.field.value });
		} else if( event.eventType == IET_DELETE_LIST_ITEM && event.field == this.field) {
			this.setState({	value: this.field.value });
		}
	}
	handleRowClick = (index) => {
		this.setState({selectedIndex: index})
	}

	addItem = () => {
		this.props.instance.addListItem(this.field);
		var lastIndex = this.field.value.length-1;
		this.setState({ selectedIndex: lastIndex });
	}
	moveUpItem = () => {
		var index = this.state.selectedIndex;
		if( index <= 0 )
			return;
		if( this.props.useProposal && this.field.value[index].isProposal )
			return;
		this.props.instance.moveUpItem(this.field, index);
		this.setState({ selectedIndex: index-1 });
	}
	moveDownItem = () => {
		var index = this.state.selectedIndex;
		if( index >= this.field.value.length-1 )
			return;
		if( this.props.useProposal && index >= this.field.value.length-2 )
			return;
		this.props.instance.moveDownItem(this.field, index);
		this.setState({ selectedIndex: index+1 });
	}
	deleteItem = () => {
		var index = this.state.selectedIndex;
		if( index < 0 || index >= this.field.value.length )
			return;
		if( this.props.useProposal && this.field.value[index].isProposal )
			return;
			
		this.props.instance.deleteListItem(this.field, index);
		if( index >= this.field.value.length ) {
			this.setState({ selectedIndex: index-1 });
		}
			
	}	

	createButtonAddHtml() {
		var readOnly = getDefinedValue( this.props.readOnly, false);
		if( this.props.useProposal ) 
			var buttonAddHtml = null;
		else
			buttonAddHtml = (
				<button type='button' className={stylesPlain.buttonIcon} style={{marginRight: '5px'}}
					onClick={this.addItem} disabled={readOnly}
				>
					<img src={iconAdd} />
				</button>
			);
		return buttonAddHtml;
	}
	createMoveItemsButtonsHtml() {
		var readOnly = getDefinedValue( this.props.readOnly, false);
		if( this.disableMoveItems )
			var moveItemsButtonsHtml = null;
		else {
			moveItemsButtonsHtml = (
				<React.Fragment>
					<button type='button' className={stylesPlain.buttonIcon}
						onClick={this.moveUpItem} disabled={readOnly}
					>
						<img src={iconMoveUp} />
					</button>
					<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px'}}
						onClick={this.moveDownItem} disabled={readOnly}
					>
						<img src={iconMoveDown} />
					</button>
				</React.Fragment>
			)
		}
		return moveItemsButtonsHtml;
	}
	createLabelStyle() {
		return {marginRight: '10px'};
	}
	createTitleHtml() {
		if( this.props.title ) {
			var labelStyle = {};
			appendListStyle(labelStyle, [
				this.createLabelStyle(), 
				getDefinedItem(this.props, ['style', 'label'], {})
			]);
			var titleHtml = (<Label title={this.props.title} tooltip={this.props.tooltip} style={labelStyle} />);
		} else 
			titleHtml = null;
		return titleHtml;
	}
	createHeaderHtml() {
		if(this.props.header) {
			return ( <this.props.header table={this} /> )
		}
		
		var readOnly = getDefinedValue( this.props.readOnly, false);
		if( this.props.labelEmptyToHeader && this.field.value.length == 0 ) {
			var labelEmptyHtml = (
				<div style={{marginRight: '15px', fontStyle: 'italic', color: 'grey'}}>
					{this.resourceFieldList.labelEmptyTable}
				</div>
			);
			var moveItemsButtonsHtml = null;
			var buttonDeleteHtml = null;
		} else {
			labelEmptyHtml = null;
			moveItemsButtonsHtml = this.createMoveItemsButtonsHtml();
			buttonDeleteHtml = (
				<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '15px'}}
					onClick={this.deleteItem} disabled={readOnly}
				>
					<img src={iconDelete} />
				</button>
			);
		}
		var divHeaderStyle = {display: 'flex', alignItems: 'center', marginBottom: '5px'};
		divHeaderStyle.width = getDefinedItem( this.props, ['table','width'], '100%');
		var headerHtml = (
			<div style={divHeaderStyle}>
				{this.createTitleHtml()}
				{labelEmptyHtml}
				<div>
					{this.createButtonAddHtml()}
					{moveItemsButtonsHtml}
					{buttonDeleteHtml}
				</div>
			</div>
		);
		return headerHtml;
	}
	
}

export default FieldList;

export const ENUMERATION_CONTRIBUTOR_TYPE = 'ct';
export const ENUMERATION_LANGUAGE = 'lg';
export const ENUMERATION_MATERIAL_TYPE = 'ma';
export const ENUMERATION_SHARE_TYPE = 'sh';
export const ENUMERATION_TEXT_FORMAT = 'tf';
export const ENUMERATION_TITLE_TYPE = 'tt';

export const STRING_FORMAT_INTEGER = "int";
export const STRING_FORMAT_DECIMAL = "dec";
export const STRING_FORMAT_FORBIDDEN_VALUES = "fbdval";
export const STRING_FORMAT_ISBN = "isbn";

export const FILE_WAS_SET = "wasSet";
export const FILE_WAS_CLEARED = "wasCleared";

import React from 'react';
import stylesPlain from './plain.module.css';

import Field from './Field';

import { getDefinedValue, getCurrentResource } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldString extends Field {

	refInput = null;
	cursorStart = 0;
	cursorEnd = 0;

	/*
		styleInput
		styleDiv
		multiline
		rows
		cols
		inputType // text, password
		autoComplete // on, off
		validator {
			isValueValid - function
			validBackground
			invalidBackground
		}
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldString;
		this.refInput = React.createRef();
		this.state.value = this.field ? getDefinedValue( this.field.value, '') : '';
	}
	completeComponentDidUpdate(prevProps, prevState, doInit) {
		if ( doInit ) {
			this.setState({ value: getDefinedValue( this.field.value, '') });
		}
		this.setCursorPositions();
	}
	
	setCursorPositions = () => {
		//reset the cursor position for input
		this.refInput.current.selectionStart = this.cursorStart;
		this.refInput.current.selectionEnd = this.cursorEnd;
	};
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	handleValueChange = e => {
		var value = e.target.value;
		this.cursorStart = e.target.selectionStart;
		this.cursorEnd = e.target.selectionEnd;
		if( this.props.validator ) {
			
		}
		this.props.instance.setFieldValue( this.field, value )
	}
	handleOnFocus = e => {
		this.cursorStart = e.target.selectionStart;
		this.cursorEnd = e.target.selectionEnd;
	}
	handleOnClick = e => {
		this.cursorStart = e.target.selectionStart;
		this.cursorEnd = e.target.selectionEnd;
	}
	goToUrl = () => {
		var value = this.state.value;
		if( value ) {
			if( ! value.startsWith("http") )
				value = "http://" + value;
			window.open( value );
		}
	}
	
	render() {
		var multiline = getDefinedValue( this.props.multiline, false);
		var styleInput = getDefinedValue( {...this.props.styleInput}, {});
		var readOnly = getDefinedValue( this.props.readOnly, false);
		var isURL = this.field != null && this.field.model.isURL;
		
		if( multiline ) {
			var rows = getDefinedValue( this.props.rows, 4);
			var cols = getDefinedValue( this.props.cols, 30);
			return (
				<textarea ref={this.refInput}
					className={stylesPlain.textArea} style={styleInput} rows={rows} cols={cols} 
					readOnly={readOnly} value={this.state.value} onChange={this.handleValueChange}
				/>
			);
		}
		
		if( isURL ) {
			if( readOnly ) {
				// TODO return <a href= />
			}
			var styleDiv = getDefinedValue( {...this.props.styleDiv}, {} )
			styleDiv.display = 'flex';
			styleInput.flexGrow = '1';
			//styleInput.padding = '3px';
			return (
				<div style={styleDiv}>
					<input type='text' ref={this.refInput} className={stylesPlain.inputFieldString} style={styleInput}
						value={this.state.value} onChange={ this.handleValueChange}
					/>
					<button type='button' style={{marginLeft: '10px'}} onClick={this.goToUrl}
						title={this.resource.buttonGoToUrlTooltip}
					>
						{this.resource.buttonGoToUrl}
					</button>
				</div>
			);
		}
		
		var type = getDefinedValue( this.props.inputType, 'text');
		var autoComplete = getDefinedValue( this.props.autoComplete, 'on');
		
		return (
			<input type={type} ref={this.refInput} className={stylesPlain.inputFieldString} style={styleInput} 
				autoComplete={autoComplete} readOnly={readOnly}
				value={this.state.value} onChange={ this.handleValueChange} 
				onFocus={this.handleOnFocus} onClick={this.handleOnClick}
			/>
		);
	}
}

export default FieldString;

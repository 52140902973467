import React from 'react';
import stylesPlain from './plain.module.css';

import { getDefinedValue, validDecimal } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldDecimal extends React.Component {

	field = null;
	ignoreInstanceChange = false;
	validator = null;

	/*
		plain                      mandatory
		instance                   mandatory
		parentField                optional
		path                       mandatory
		styleInput
		readOnly
		validator {
			isValueValid - function
			validBackground
			invalidBackground
		}
	*/
	constructor(props) {
		super(props);
		this.init();
		this.validator = props.validator;
		if( ! this.validator ) 
			this.validator = {
				isValueValid: validDecimal,
				validBackground: 'white',
				invalidBackground: 'lightYellow'
			}
		this.state = {
			inputValue: this.field ? this.field.value : '',
			valueIsValid: true,
			readOnly: getDefinedValue( props.readOnly, false)
		}
	}
	componentDidUpdate(prevProps) {
		var doInit = false;
		var updateListener = ( prevProps.instance !== this.props.instance );
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		if ( doInit ) {
			this.init();
			this.setState({ inputValue: this.field ? this.field.value : '' });
		}
		if( updateListener ) {
			if( prevProps.instance )
				prevProps.instance.removeChangeListener( this );
			if( this.props.instance )
				this.props.instance.addChangeListener( this );
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init = () => {
		if( this.props.instance ) {
			if( this.props.parentField )
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			else
				this.field = this.props.instance.getField(this.props.path);
		}
	}

	handleInstanceChange = async (event) => {
		if( this.ignoreInstanceChange )
			return;
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ inputValue: event.newValue, valueIsValid: true });
		}
	}
	handleValueChange = e => {
		var value = e.target.value;
		var valueIsValid = this.validator.isValueValid( value ); 
		if( valueIsValid ) {
			this.props.instance.setFieldValue( this.field, value )
		} else {
			this.ignoreInstanceChange = true;
			this.props.plain.instance.setFieldValue( this.field, null )
			this.ignoreInstanceChange = false;
			this.setState({ inputValue: value, valueIsValid: valueIsValid });
		}
	}

	render() {
		var style = getDefinedValue( {...this.props.styleInput}, {});
		style.backgroundColor = this.state.valueIsValid ? this.validator.validBackground : this.validator.invalidBackground;
		var readOnly = getDefinedValue( this.props.readOnly, false);
		
		return (
			<input type='text' className={stylesPlain.inputFieldString} style={style} readOnly={readOnly}
				value={this.state.inputValue} onChange={this.handleValueChange}
			/>
		);
	}
}

export default FieldDecimal;

import {formatDateTime, parseDateTime} from '../tools';

const FIELD_ROOT_ID = 'root';

// Data tpes
export const DATATYPE_GROUP = 'Group';
export const DATATYPE_LIST = 'List';
export const DATATYPE_STRING = 'String';
export const DATATYPE_MULTILANG_STRING = 'MultilangString';
export const DATATYPE_ENUM = 'Enum';
export const DATATYPE_INTEGER = 'Integer';
export const DATATYPE_DECIMAL = 'Decimal';
export const DATATYPE_DATETIME = 'Datetime';
export const DATATYPE_REFERENCE = 'Reference';

// Instance Edition Types
export const IET_SET_VALUE = 'setValue';
export const IET_ADD_LIST_ITEM = 'addItem';
export const IET_MOVE_UP_LIST_ITEM = 'moveUpItem';
export const IET_MOVE_DOWN_LIST_ITEM = 'moveDownItem';
export const IET_DELETE_LIST_ITEM = 'deleteItem';

var internalInstanceIdCounter = 1;

export class Instance {

	id = null;
	globalId = null;
	model = null;
	fieldCounter = 0;
	systemData = {
		ownerUser: null,
		ownerGroup: null,
		createDate: null,
		modifyDate: null,
		createUser: null,
		modifyUser: null,
	};
	
	rootField = {
		model: {
			fieldId: FIELD_ROOT_ID,
			dataType: DATATYPE_GROUP,
			subfields: []
		},
		fieldId: 'fld' + (this.fieldCounter++),
		parent: null,
		value: {} 
	};
	
	listChangeListener = [];
	
	constructor(instanceModel, bean) {
		
		this.internalId = internalInstanceIdCounter++;
		this.model = instanceModel;
		if( bean != null ) {
			this.id = bean.id;
			this.globalId = bean.globalId;
			this.systemData.ownerUser = bean.ownerUser;
			this.systemData.ownerGroup = bean.ownerGroup;
			this.systemData.createDate = bean.createDate;
			this.systemData.modifyDate = bean.modifyDate;
			this.systemData.createUser = bean.createUser;
			this.systemData.modifyUser = bean.modifyUser;
		}
		
		for(let sfModel of instanceModel.subfields) {
			if( bean == null ) {
				var field = this.createInstanceField(sfModel, null);
			} else {
				field = this.createInstanceField(sfModel, bean[sfModel.fieldId]);
			}
			field.parent = this.rootField; 
			this.rootField.value[sfModel.fieldId] = field;
			this.rootField.model.subfields.push(sfModel);
		}
	}
	
	createInstanceField(fieldModel, beanField) {
		var field = {
			instance: this,
			model: fieldModel,
			fieldId: 'fld' + (this.fieldCounter++),
			parent: null,
			value: null
		}
		
		if( fieldModel.dataType == DATATYPE_LIST ) {
			field.value = [];
			if( beanField != null ) {
				for(let itemOfBean of beanField) {
					var item = this.createInstanceField(fieldModel.item, itemOfBean);
					item.parent = field;
					field.value.push( item );
				}
			}
		} else if( fieldModel.dataType == DATATYPE_GROUP ) {
			field.value = {};
			for(let sfModel of fieldModel.subfields) {
				if( beanField == null ) {
					var subfield = this.createInstanceField(sfModel, null);
				} else {
					subfield = this.createInstanceField(sfModel, beanField[sfModel.fieldId]);
				}
				subfield.parent = field; 
				field.value[sfModel.fieldId] = subfield;
			}
		} else if( fieldModel.dataType == DATATYPE_MULTILANG_STRING ) {
			field.value = { lang: '', value: '' };
			if( beanField != null ) {
				field.value.lang = beanField.lang; 
				field.value.value = beanField.value; 
			}
		} else {
			if( beanField )
				field.value = beanField;
			else if( fieldModel.initialValue )
				field.value = fieldModel.initialValue;
			else
				field.value = null;
			if( fieldModel.dataType == DATATYPE_DATETIME ) {
				if( field.value != null && field.value != '' ) {
					var d = new Date(field.value);
					field.value = formatDateTime( d );
				}
			}
		}
		
		return field;
	}

	addChangeListener(listener) {
		this.listChangeListener.push( listener );
	}
	removeChangeListener(listener) {
		for(let i=this.listChangeListener.length-1; i >= 0; i--) {
			if( this.listChangeListener[i] === listener ) {
				this.listChangeListener.splice(i, 1);
			}
		}
	}
	
	notifyChangeListeners = async (event) => {
		console.log( "Instance changed", event, this );
		for(let listener of this.listChangeListener) {
			await listener.handleInstanceChange(event);
		}
	}

	getField(path, parentField) {
		if( ! parentField )
			parentField = this.rootField;
		if( path.startsWith('/') )
			path = path.substring(1);
			
		var index = path.indexOf('/');
		if( index == -1 ) {
			var fieldId = path;
			return parentField.value[fieldId];
		} else {
			fieldId = path.substring( 0, index );
			path = path.substring(index+1);
			var childField = parentField.value[fieldId];
			if( childField == null || childField === undefined )
				return null;
			return this.getField(path, childField);
		}
	}
	getListItemIndex(fieldItem) {
		var fieldList = fieldItem.parent;
		for(let i=0; i < fieldList.value.length; i++) {
			if( fieldList.value[i] == fieldItem )
				return i;
		}
		return -1;
	}

	setFieldValue(field, newValue) {
		var oldValue = field.value;
		field.value = newValue;
		var event = {
			eventType: IET_SET_VALUE,
			field: field,
			oldValue: oldValue,
			newValue: newValue
		}
		this.notifyChangeListeners(event);
	}
	addListItem(fieldList, item) {
		if( item == undefined || item == null )
			item = this.createInstanceField(fieldList.model.item, null);
		item.parent = fieldList;
		fieldList.value.push( item );
		
		var event = {
			eventType: IET_ADD_LIST_ITEM,
			field: fieldList,
		}
		this.notifyChangeListeners(event);
		return item;
	}
	moveUpItem(fieldList, index) {
		if( index <= 0 )
			return;
		var prev = fieldList.value[index-1];
		fieldList.value[index-1] = fieldList.value[index];
		fieldList.value[index] = prev; 

		var event = {
			eventType: IET_MOVE_UP_LIST_ITEM,
			field: fieldList,
			itemIndex: index,
		}
		this.notifyChangeListeners(event);
	}
	moveDownItem(fieldList, index) {
		if( index >= fieldList.value.length-1 )
			return;
		var next = fieldList.value[index+1];
		fieldList.value[index+1] = fieldList.value[index];
		fieldList.value[index] = next; 

		var event = {
			eventType: IET_MOVE_DOWN_LIST_ITEM,
			field: fieldList,
			itemIndex: index,
		}
		this.notifyChangeListeners(event);
	}
	deleteListItem(fieldList, index) {
		fieldList.value.splice(index, 1);
		
		var event = {
			eventType: IET_DELETE_LIST_ITEM,
			field: fieldList,
			itemIndex: index,
		}
		this.notifyChangeListeners(event);
	}
	
	createBean() {
		var bean = {
			id: this.id,
			globalId : this.globalId,
			ownerUser: this.systemData.ownerUser,
			ownerGroup: this.systemData.ownerGroup,
			createDate: this.systemData.createDate,
			modifyDate: this.systemData.modifyDate,
			createUser: this.systemData.createUser,
			modifyUser: this.systemData.modifyUser,
		}
		
		for(let subfieldModel of this.rootField.model.subfields) {
			this.addBeanField(bean, this.rootField.value[subfieldModel.fieldId]);
		}
		
		return bean;
	}
	addBeanField(beanParent, instanceField) {
		if( instanceField.isProposal )
			return;
		if( instanceField.model.dataType == DATATYPE_LIST ) {
			beanParent[instanceField.model.fieldId] = [];
			for(let item of instanceField.value)
				this.addBeanField(beanParent[instanceField.model.fieldId], item);
		} else if( instanceField.model.dataType == DATATYPE_GROUP ) {
			var group = {};
			for(let subfieldModel of instanceField.model.subfields) {
				this.addBeanField(group, instanceField.value[subfieldModel.fieldId]);
			}
			if( instanceField.parent.model.dataType == DATATYPE_LIST )
				beanParent.push( group );
			else
				beanParent[instanceField.model.fieldId] = group;;
		} else {
			var value = instanceField.value;
			if( instanceField.model.dataType == DATATYPE_DATETIME ) {
				value = parseDateTime( value );
			}
			if( instanceField.parent.model.dataType == DATATYPE_LIST )
				beanParent.push( value );
			else
				beanParent[instanceField.model.fieldId] = value;
		}
	}

}


import React from 'react';
import stylesPlain from './plain.module.css';

import {getDefaultStyles, appendStyle} from '../../util/realm/styles';

class Label extends React.Component {

	/*
		props
			title
			tooltip
			style
	*/

	render() {
		var title = this.props.title;
		var tooltip = this.props.tooltip;

		var style = {};
		appendStyle(style, getDefaultStyles().label);
		appendStyle(style, this.props.style);

		if( tooltip )
			return (
				<div className={stylesPlain.divLabelWithTooltip} style={style}>
					{title} 
					<span className={stylesPlain.tooltiptext}>{tooltip}</span>
				</div>
			);
		else {
			return (
				<div style={style}>
					{title} 
				</div>
			);
		}
	}

}

export default Label;
import React from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage from '../../components/plain/PlainPage';
import Label from '../../components/plain/Label';
import FieldDate from '../../components/plain/FieldDate';
import FieldString from '../../components/plain/FieldString';
import FieldEnum from '../../components/plain/FieldEnum';
import TabbedPane from '../../components/plain/TabbedPane';

import TabBrParameters from './components/TabBrParameters';
import TabBrIdentification from './components/TabBrIdentification';
import TabBrNames from './components/TabBrNames';
import TabBrAuthors from './components/TabBrAuthors';
import TabBrEdition from './components/TabBrEdition';
import TabBrPhysChar from './components/TabBrPhysChar';
import TabBrContent from './components/TabBrContent';
import TabBrDocLinks from './components/TabBrDocLinks';

import {getLanguage} from '../../util/localStorage';
import {getDefinedValue} from '../../util/tools';
import {FORM_BOOK_PLAIN} from '../../util/consts/forms';
import { 
	ENUMERATION_CONTRIBUTOR_TYPE, ENUMERATION_LANGUAGE, ENUMERATION_MATERIAL_TYPE, 
	ENUMERATION_SHARE_TYPE, ENUMERATION_TITLE_TYPE 
} from '../../util/consts/common';

class BookPlainPage extends PlainPage {

	enumContributorType = null;
	enumLanguage = null;
	enumMaterialType = null;
	enumShareType = null;
	enumTitleType = null;

	constructor(props) {
		super(props);
		this.formPath = FORM_BOOK_PLAIN;
		this.formTitle = 'Книга';
		this.buildInstanceCard = this.buildBookCard;
	}
	async completeInit() {
		this.enumContributorType = this.realm.getEnumeration( ENUMERATION_CONTRIBUTOR_TYPE );
		this.enumLanguage = this.realm.getEnumeration( ENUMERATION_LANGUAGE );
		this.enumMaterialType = this.realm.getEnumeration( ENUMERATION_MATERIAL_TYPE );
		this.enumShareType = this.realm.getEnumeration( ENUMERATION_SHARE_TYPE );
		this.enumTitleType = this.realm.getEnumeration( ENUMERATION_TITLE_TYPE );
		return true;
	}
	componentDidMount = async () => {
		this.init('Book')
	}
	createInstanceFromBean(bean) {
		var instance = super.createInstanceFromBean(bean);
		
		let fieldIdentificationItem = this.createArrayItem(instance, instance.rootField.value.identification);
		if( fieldIdentificationItem != null ) {
			fieldIdentificationItem.value.type.value = 'ISBN'; 
		}
		let fieldLanguageItem = this.createArrayItem(instance, instance.rootField.value.content.value.language);
		if( fieldLanguageItem != null ) {
			fieldLanguageItem.value = 'ukr'; 
		}
		this.createArrayItem(instance, instance.rootField.value.content.value.audience);
		
		return instance;
	}
	createArrayItem = (instance, arrayField) => {
		if( arrayField.value.length > 0 )
			return null;
		instance.addListItem(arrayField, null);
		var item = arrayField.value[0];
		return item;
	}

	addContributorName(currentName, contributor, prefix) {
		var currentLanguage = getLanguage();
		var name = null;
		for(let n of contributor.value.names.value) {
			if( n.value.language.value == currentLanguage && n.value.shortName.value ) {
				name = n;
				break;
			}
		}
		if( name == null && contributor.value.names.value.length > 0)
			name = contributor.value.names.value[0];
		if( name != null  && name.value.shortName.value ) {
			var pref = name.value.role.value;
			if( ! pref )
				pref = getDefinedValue(prefix, '');
			if( currentName )
				currentName += ', ' + name.value.shortName.value;
			else
				currentName = pref + ' ' + name.value.shortName.value;
		} 
		return currentName;
	}
	buildBookCard = (bookInstance) => {
		var currentLanguage = getLanguage();
		var card = '';
		
		var titleArea = '';
		var titleAreaCt = ''
		for(let ttl of bookInstance.rootField.value.title.value) {
			if(ttl.value.titleType.value == 'Modern') {
				if( ttl.value.language.value == currentLanguage ) {
					titleArea = ttl.value.mainName.value; 
					if( ttl.value.subName.value ) 
						titleArea += ": " + ttl.value.subName.value;
					break;
				} else if( ttl.value.language.value == this.instance.rootField.value.parameter.value.language.value ) {
					titleAreaCt = ttl.value.mainName.value;
				}
			}
		}
		if( titleArea == '' )
			titleArea = titleAreaCt;
		var materialType = this.instance.rootField.value.edition.value.materialType.value;
		if( materialType ) {
			var materialTypeText = this.realm.getEnumerationItemText( this.enumMaterialType, materialType);
			titleArea += ' [' + materialTypeText + ']';
		}

		var author = '', compiler = '', editor = '', translator = '', illustrator = '';
		for(let con of bookInstance.rootField.value.contributor.value) {
			var contriburorType = con.value.type.value;
			var contriburorName = this.realm.getEnumerationItemText( this.enumContributorType, contriburorType);
			if( contriburorType == 'A01' ) { // AUTHOR
				author = this.addContributorName(author, con, '');
			} else if( contriburorType == 'C01' ) { // COMPILER
				compiler = this.addContributorName(compiler, con, contriburorName);
			} else if( contriburorType == 'B01' ) { // EDITOR
				editor = this.addContributorName(editor, con, contriburorName);
			} else if( contriburorType == 'B06' ) { // TRANSLATOR
				translator = this.addContributorName(translator, con, contriburorName);
			} else if( contriburorType == 'A12' ) { // ILLUSTRATOR
				illustrator = this.addContributorName(illustrator, con, contriburorName);
			}
		}
		var respArea = author
		if( compiler )
			respArea += (respArea) ? " ; " + compiler : compiler;
		if( editor )
			respArea += (respArea)? " ; " + editor : editor;
		if( translator )
			respArea += (respArea)? " ; " + translator : translator;
		if( illustrator )
			respArea += (respArea)? " ; " + illustrator : illustrator;
		
		card += titleArea;
		if( respArea )
			card += " / " + respArea;
		return card;
	}
	
	
	renderBody() {
		var tabs = [
			{
				rowId: 'first',
				tabs: [
					{
						title: 'Параметри',
						component: <TabBrParameters plain={this} instance={this.state.instance} />,
					},
					{
						title: 'Ідентифікація',
						component: <TabBrIdentification plain={this} instance={this.state.instance} />,
					},
					{
						title: 'Назви',
						component: <TabBrNames plain={this} instance={this.state.instance} />,
					},
					{
						title: 'Автори',
						component: <TabBrAuthors plain={this} instance={this.state.instance} />,
					},
					{
						title: 'Авторитетні записи',
						component: <TabStab body={'Авторитетні записи'}/>,
					},
					{
						title: 'Видання',
						component: <TabBrEdition plain={this} instance={this.state.instance} />,
					},
					{
						title: 'Фізичні характеристики',
						component: <TabBrPhysChar plain={this} instance={this.state.instance} />,
					},
				]
			},
			{
				rowId: 'second',
				tabs: [
					{
						title: 'Зміст',
						component: <TabBrContent plain={this} instance={this.state.instance} />,
					},
					{
						title: 'Зв\'язки з документами',
						component: <TabBrDocLinks plain={this} instance={this.state.instance} />,
					},
					{
						title: 'Теми класифікатора',
						component: <TabStab body={'Теми класифікатора'}/>,
					},
					{
						title: 'Електронні копії',
						component: <TabStab body={'Електронні копії'}/>,
					},
					{
						title: 'Зображення',
						component: <TabStab body={'Зображення'}/>,
					},
					{
						title: 'Упорядкування',
						component: <TabStab body={'Упорядкування'}/>,
					},
					{
						title: 'Екранізації',
						component: <TabStab body={'Екранізації'}/>,
					},
					{
						title: 'Відзнаки і нагороди',
						component: <TabStab body={'Відзнаки і нагороди'}/>,
					},
				]
			},
		];
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<Label title={'Посилання на книгу'} />
						<FieldString instance={this.state.instance} path={'/publisherUri'} plain={this}
							styleDiv={{flexGrow: '1', marginLeft: '10px'}} 
							styleInput={{}} 
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<Label title={'ISBN'} />
						<FieldString instance={this.state.instance} path={'/identification/0/number'} plain={this} 
							styleInput={{flexGrow: '1', marginLeft: '10px', marginRight: '30px'}} 
						/>
						<Label title={'Мова видання'} />
						<FieldEnum instance={this.state.instance} path={'/content/language/0'} plain={this} 
							styleSelect={{flexGrow: '1', marginLeft: '10px', marginRight: '30px'}} 
						/>
						<Label title={'Книжкова палата'} />
						<FieldDate instance={this.state.instance} path={'/bcudate'} plain={this} 
							styleInput={{flexGrow: '1', marginLeft: '10px'}} 
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<Label title={'Форма видання'} />
						<FieldEnum instance={this.state.instance} path={'/edition/productForm'} plain={this} 
							styleSelect={{marginLeft: '10px', marginRight: '30px', flexGrow: '1'}} 
						/>
						<Label title={'Аудиторія'} />
						<FieldEnum instance={this.state.instance} path={'/content/audience/0'} plain={this} 
							styleSelect={{marginLeft: '10px', marginRight: '30px', flexGrow: '1'}} 
						/>
						<Label title={'Тип літератури'} />
						<FieldEnum instance={this.state.instance} path={'/arrange/litType'} plain={this} 
							styleSelect={{marginLeft: '10px', flexGrow: '1'}} 
						/>
					</div>
					<TabbedPane tabs={tabs} divStyle={{border: '1px solid gray', marginTop: '10px'}}/>
				</div>
			</div>
		)
	}
}

class TabStab extends React.Component {
	
	render() {
		return (
			<div style={{minHeight: '200px'}}>
				<div style={{marginTop: '70px', textAlign: 'center', fontSize: '20pt'}}>
					{this.props.body}
				</div>
			</div>
		)
	}
}

export default BookPlainPage;

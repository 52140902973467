import React from 'react';

import Label from '../../../components/plain/Label';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';

class TabBrParameters extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<Label title={'Ідентифікатор'} />
					<FieldString instance={instance} path={'/parameter/recordId'} plain={plain} 
						styleInput={{flexGrow: '1', marginLeft: '10px', marginRight: '30px'}} 
					/>
					<Label title={'Номер'} />
					<FieldString instance={instance} path={'/parameter/number'} plain={plain} 
						styleInput={{flexGrow: '1', marginLeft: '10px'}} 
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<Label title={'Версія'} />
					<FieldString instance={instance} path={'/parameter/versionId'} plain={plain} 
						styleInput={{flexGrow: '1', marginLeft: '10px', marginRight: '30px'}} 
					/>
					<Label title={'Дата'} />
					<FieldString instance={instance} path={'/parameter/date'} plain={plain} 
						styleInput={{flexGrow: '1', marginLeft: '10px'}} 
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<Label title={'Країна каталогізації'} />
					<FieldEnum instance={instance} path={'/parameter/country'} plain={plain} 
						styleSelect={{flexGrow: '1', marginLeft: '10px', marginRight: '30px'}} 
					/>
					<Label title={'Тип агенції'} />
					<FieldEnum instance={instance} path={'/parameter/agencyType'} plain={plain} 
						styleSelect={{flexGrow: '1', marginLeft: '10px'}} 
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<Label title={'Агенція каталогізації'} />
					<FieldString instance={instance} path={'/parameter/agency'} plain={plain} 
						styleInput={{flexGrow: '1', marginLeft: '10px'}} 
					/>
				</div>
			</div>
		)
	}
}

export default TabBrParameters; 
import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';

const table = {
	columns: [
		{ columnId: 'type', title: 'Тип', tdHeaderStyle: {width: '10%'}},
		{ columnId: 'number', title: 'Ідентифікатор', tdHeaderStyle: {width: '20%'}},
		{ columnId: 'version', title: 'Версія', tdHeaderStyle: {width: '20%'}},
		{ columnId: 'condition', title: 'Умови доступності', tdHeaderStyle: {width: '20%'}},
		{ columnId: 'qualification', title: 'Уточнення системи', tdHeaderStyle: {width: '15%'}},
		{ columnId: 'place', title: 'Місце ідентифікації', tdHeaderStyle: {width: '15%'}},
	],
	width: '100%',
	styles: {
		table: {},
		thead: {textAlign: 'center', fontWeight: 'bold'},
		trHeader: {height: '43px'}
	}
}

class TabBrIdentification extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<FieldList_TableGroup instance={instance} path={'/identification'} 
					plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter}
					table={table} cell={Cell}
				/>
			</div>
		);
	}
}

class Cell extends React.Component {

	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'type' ) {
			return <FieldEnum instance={this.props.instance} path={'type'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'number' ) {
			return <FieldString instance={this.props.instance} path={'number'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'version' ) {
			return <FieldString instance={this.props.instance} path={'version'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'condition' ) {
			return <FieldString instance={this.props.instance} path={'condition'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'qualification' ) {
			return <FieldString instance={this.props.instance} path={'qualification'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		if( columnId == 'place' ) {
			return <FieldString instance={this.props.instance} path={'place'} plain={plain} parentField={fieldGroup} styleInput={{width: '95%'}} />
		}
		return null;
	}

}

export default TabBrIdentification;
import React from 'react';

import FieldList from './FieldList';

import { getDefinedValue } from '../../util/tools';
import { mergeStyles } from '../../util/realm/styles';

export const DIRECTION_HORIZONTAL = 'horizontal';
export const DIRECTION_VERTICAL = 'vertical';

class FieldList_CardsPanel extends FieldList {
	
	field = null;

	/*
		direction                  (horizontal (default) | vertical)
		itemComponent
		itemCardBuilder
		styles
			divMain
			divCardsBlock
			divCard
	*/
	constructor(props) {
		super(props);
		this.init();
		this.state.compoundCard = '';
	}
	
	getCompoundCard = () => {
		var compoundCard = '';
		for(let i=0; i < this.field.value.length; i++) {
			let itemField = this.field.value[i];
			compoundCard += this.props.itemCardBuilder(itemField, i);
		}
		return compoundCard;
	}
	async handleInstanceChange(event) {
		await super.handleInstanceChange( event );
		var compoundCard = this.getCompoundCard();
		if( compoundCard != this.state.compoundCard ) {
			this.setState({compoundCard: compoundCard});
		}
	}
	
	createLabelStyle() {
		var labelStyle = super.createLabelStyle();
		labelStyle.fontWeight = 'bold';
		labelStyle.fontSize = '14pt';
		return labelStyle;
	}
	render() {
		var styles = getDefinedValue( this.props.styles, {});
		var styleDivCardsBlock = mergeStyles([localStyles.divCardsBlock, styles.divCardsBlock ]);
		var styleDivCard = mergeStyles([localStyles.divCard, styles.divCard]);

		var cardsHtml = this.field.value.map((itemField, index) => {
			var divStyle = {...styleDivCard};
			if( index == this.state.selectedIndex )
				divStyle.backgroundColor = 'lightBlue';
			var card = this.props.itemCardBuilder(itemField, index);
			if( ! card )
				card = '...' 
			return (
				<div key={index} style={divStyle} onClick={ () => {this.handleCardClick(index)} }>
					{card}
				</div>
			)
		});
		var listItemInfo = {
			index: this.state.selectedIndex,
			isLastItem: false,
			divButtonsHtml: null
		};
		if( this.state.selectedIndex < 0 || this.state.selectedIndex >= this.field.value.length )
			var selectedItemHtml = null;
		else {
			var activeField = this.field.value[this.state.selectedIndex];
			selectedItemHtml = (
				<this.props.itemComponent fieldGroup={activeField} listItemInfo={listItemInfo} 
					instance={this.props.instance} plain={this.props.plain} 
				/>
			);
		}

		var divMainStyle = {...getDefinedValue(styles.divMainStyle, {})}; 
		var direction = getDefinedValue(this.props.direction, DIRECTION_HORIZONTAL);
		if( direction == DIRECTION_HORIZONTAL )
			divMainStyle.display = 'flex';		
		
		var headerHtml = this.createHeaderHtml();
		
		return (
			<div style={divMainStyle}>
				<div style={styleDivCardsBlock}>
					{headerHtml}
					{cardsHtml}
				</div>
				{selectedItemHtml}
			</div>
		)
	}
}

const localStyles = {
	divCardsBlock: {
		padding: '10px',
		backgroundColor: 'lightGray',
	},
	divCardsHeader: {
		marginBottom: '10px',
		display: 'flex',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '13pt'
	},
	divCard: {
		padding: '7px',
		cursor: 'pointer',
		backgroundColor: 'lightGray',
		minHeight: '18px'
	}
}

export default FieldList_CardsPanel;

import React from 'react';

import Label from '../../../components/plain/Label';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_CardsPanel, {DIRECTION_VERTICAL} from '../../../components/plain/FieldList_CardsPanel';
import FieldList_Reference from '../../../components/plain/FieldList_Reference';

import {getLanguage} from '../../../util/localStorage';
import { AUTHORITY_AUTHOR_PATH } from '../../../util/routes';
import { FORM_AUTHORITY_AUTHOR_TABLE } from '../../../util/consts/forms';


class TabBrAuthors extends React.Component {
	
	constructor(props) {
		super(props);
	}

	contributorCardBuilder = (fieldContributor, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var contriburorType = fieldContributor.value.type.value;
		var contriburorTypeText = plain.realm.getEnumerationItemText( plain.enumContributorType, contriburorType);
		if( contriburorTypeText )
			card += contriburorTypeText + ': '

		var currentLanguage = getLanguage();
		var name = null;
		for(let n of fieldContributor.value.names.value) {
			if( n.value.language.value == currentLanguage && 
					(n.value.shortName.value || n.value.fullName.value) ) 
			{
				name = n;
				break;
			}
		}
		if( name == null && fieldContributor.value.names.value.length > 0)
			name = fieldContributor.value.names.value[0];
		if( name ) {
			if( name.value.shortName.value )
				card += name.value.shortName.value;
			else if( name.value.fullName.value )
				card += name.value.fullName.value;
		}
		
		return card;
	}

	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{padding: '10px' }}>
				<FieldList_CardsPanel path={'/contributor'} instance={instance} plain={plain} 
					itemComponent={ContributorPanel} itemCardBuilder={this.contributorCardBuilder}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '300px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGrey',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		);
	}
}

class ContributorPanel extends React.Component {

	refContributorAuthorityNodes = [
		{
			classId: 'AuthorityAuthor', plainFormPath: AUTHORITY_AUTHOR_PATH, 
			selectInstanceFormId: FORM_AUTHORITY_AUTHOR_TABLE,
			selectInstanceFormTitle: 'Авторитетні записи'
		}
	];
	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	nameCardBuilder = (fieldName, index) => {
		var plain = this.props.plain;
		var card = '';
		var language = fieldName.value.language.value;
		if( language ) {
			var languageText = plain.realm.getEnumerationItemText( plain.enumLanguage, language);
			card += languageText + '; ';
		}
		if( fieldName.value.shortName.value )
			card += fieldName.value.shortName.value;
		else if( fieldName.value.fullName.value )
			card += fieldName.value.fullName.value;
		if( ! card )
			card = '...'
		return card;
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'rgb(220,220,220)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Суб\'єкт'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'subjectType'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', flexGrow: '1', marginRight: '20px'}}
						/>
						<Label title={'Роль'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'type'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', flexGrow: '1', marginRight: '20px'}}
						/>
						<Label title={'Дати'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'dates'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '20px'}}
						 />
						<Label title={'Частина'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'section'} parentField={fieldGroup} plain={plain}
							styleInput={{maxWidth: '150px', flexGrow: '1'}}
						 />
					</div>
					<FieldList_CardsPanel path={'names'} instance={instance} parentField={fieldGroup} plain={plain} 
						itemComponent={NamePanel} itemCardBuilder={this.nameCardBuilder}
						direction={DIRECTION_VERTICAL} title='Імена'
						styles={{
							divMainStyle: {
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue',
								marginBottom: '10px'
							},
							divCardsBlock: {
								backgroundColor: 'rgb(200,200,200)',
								minWidth: '300px'
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
							}
						}}
					/>
					<FieldList_Reference path={'contributorAuthority'} instance={instance} parentField={fieldGroup} plain={plain} 
						title='Авторитетні записи' styles={{divMain: {marginTop: '10px'}}}
						labelEmptyToHeader={true}
						refNodes={this.refContributorAuthorityNodes}
					/>
				</div>
			</div>
		)
	}
}

class NamePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}

	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '5px 0 0 0', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Мова'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', marginRight: '20px'}}
						/>
						<Label title={'Роль (уточнення)'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'role'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '20px'}}
						 />
						<Label title={'Тип'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'nameType'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Прізвище або назва'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'keyName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '20px'}}
						 />
						<Label title={'Ім\'я'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'name'} parentField={fieldGroup} plain={plain}
							styleInput={{maxWidth: '170px', flexGrow: '1', marginRight: '20px'}}
						 />
						<Label title={'Друге ім\'я'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'secondName'} parentField={fieldGroup} plain={plain}
							styleInput={{maxWidth: '170px', flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Скорочене ім\ʼя'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'shortName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<Label title={'Повне ім\ʼя'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'fullName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Місце'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'place'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<Label title={'Афіліація'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'affilation'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Примітки'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrAuthors;
import React from 'react';
import stylesPlain from './plain.module.css';

import { getDefinedValue } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldDate extends React.Component {

	field = null;

	/*
		plain                      mandatory
		instance                   mandatory
		parentField                optional
		path                       mandatory
		styleInput
		readOnly
	*/
	constructor(props) {
		super(props);
		this.init();
		this.state = {
			inputValue: this.field ? this.field.value : null,
			readOnly: getDefinedValue( props.readOnly, false)
		}
	}
	componentDidUpdate(prevProps) {
		var doInit = false;
		var updateListener = ( prevProps.instance !== this.props.instance );
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		if ( doInit ) {
			this.init();
			this.setState({ inputValue: this.field ? this.field.value : null });
		}
		if( updateListener ) {
			if( prevProps.instance )
				prevProps.instance.removeChangeListener( this );
			if( this.props.instance )
				this.props.instance.addChangeListener( this );
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init = () => {
		if( this.props.instance ) {
			if( this.props.parentField )
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			else
				this.field = this.props.instance.getField(this.props.path);
		}
	}
	
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ inputValue: event.newValue });
		}
	}
	handleValueChange = e => {
		var value = e.target.value;
		if( value )
			value = value.slice(0,4) + value.slice(5,7) + value.slice(8,10);
		this.props.instance.setFieldValue( this.field, value )
	}
	
	render() {
		var style = getDefinedValue( this.props.styleInput, {});
		var readOnly = getDefinedValue( this.props.readOnly, false);
		var value = this.state.value;
		if( value != null && value.length == 8) {
			value = value.slice(0,4) + '-' + value.slice(4,6) + '-' + value.slice(6,9);
		}
		return (
			<input type='date' className={stylesPlain.inputFieldString} style={style} readOnly={readOnly} 
				value={value} onChange={this.handleValueChange}
			/>
		);
	}
}

export default FieldDate;
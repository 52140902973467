import React from 'react';
import stylesPlain from './plain.module.css';

import FieldList from './FieldList';

import { getDefinedValue, getDefinedItem, getCurrentResource } from '../../util/tools';
import { ENUMERATION_LANGUAGE } from '../../util/consts/common';

export const HEADER_POSITION_TOP = 'top;'
export const HEADER_POSITION_RIGHT = 'right;'
export const HEADER_POSITION_LEFT = 'left;'

class FieldList_MultilangString extends FieldList {

	enumeration = null;
	resource = null;
	headerPosition = null; // default - top

	/*
		styles
			divMain
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldList_MultilangString;
		this.enumeration = this.props.plain.realm.getEnumeration( ENUMERATION_LANGUAGE );
		if( this.enumeration.items[0].value != '' ) {
			this.enumeration.items.unshift( {value: '', label: this.resource.enumNotDefined} )
		}
	}
	isValueEmpty(valueIndex) {
		var value = this.field.value[valueIndex].value;
		if( value == null || value == undefined )
			return true;
		return ! value.lang && ! value.value;
	}

	handleEnumLangValueChange = (e) => {
		var value = e.target.value;
		var index = this.state.selectedIndex;
		var newValue = this.field.value[index].value;
		newValue.lang = value;
		this.props.instance.setFieldValue( this.field.value[index], newValue );
	}
	handleStringValueChange = (e) => {
		var value = e.target.value;
		var index = this.state.selectedIndex;
		var newValue = this.field.value[index].value;
		console.log('newValue', newValue);
		newValue.value = value;
		console.log('newValue', newValue);
		this.props.instance.setFieldValue( this.field.value[index], newValue );
	}

	render() {
		var readOnly = getDefinedValue( this.props.readOnly, false);
		var langOptionsHtml = this.enumeration.items.map((item, index) => {
			return (<option key={index} value={item.value} title={item.description} >{item.label}</option>);
		});

		var headerHtml = this.createHeaderHtml();
		
		if( this.field.value.length == 0 ) {
			if( this.props.labelEmptyToHeader ) {
				var tableHtml = null;
			} else {
				tableHtml = (
					<div style={{fontStyle: 'italic', color: 'grey'}}>
						{this.resource.labelEmptyTable}
					</div>
				);
			}
		} else {
			var rowsHtml = this.field.value.map((itemField, index) => {
				var trStyle = {backgroundColor: 'lightGray'};
				if( index == this.state.selectedIndex )
					trStyle.backgroundColor = 'lightBlue';
				return (
					<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(index)} }>
						<td width={'200px'}>
							<select className={stylesPlain.selectEnum} style={{maxWidth: '190px'}} 
								value={this.state.value[index].value.lang} onChange={this.handleEnumLangValueChange} 
							>
								{langOptionsHtml}
							</select>
						</td>
						<td>
							<input className={stylesPlain.inputFieldString} style={{width: '98%'}} 
								readOnly={readOnly}
								value={this.state.value[index].value.value} onChange={ this.handleStringValueChange}
							/>
						</td>
					</tr>
				)
			});
			var tableStyle = {...getDefinedItem( this.props, ['table', 'styles', 'table'], {})};
			tableStyle.width = getDefinedItem( this.props, ['table','width'], '100%');
			tableHtml = (
				<table border='1' style={tableStyle}>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			);
		}
		var divMainStyle = {...getDefinedItem(this.props, ['styles', 'divMain'], {})};
		var headerPosition = getDefinedValue(this.props.headerPosition, HEADER_POSITION_TOP);
		if( headerPosition == HEADER_POSITION_RIGHT ) {
			divMainStyle.display = 'flex';
			divMainStyle.alignItems = 'start';
			if( this.field.value.length == 0 )
				var tableFlexGrow = '0';
			else
				tableFlexGrow = '1';
			return (
				<div style={divMainStyle}>
					<div style={{flexGrow: tableFlexGrow}}>
						{tableHtml}
					</div>
					<div style={{marginLeft: '10px'}}>
						{headerHtml}
					</div>
				</div>
			);
		}
		return (
			<div style={divMainStyle}>
				{headerHtml}
				{tableHtml}
			</div>
		);
	}
}

export default FieldList_MultilangString;

import React from 'react';

import Label from '../../../components/plain/Label';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_Enum from '../../../components/plain/FieldList_Enum';
import FieldList_MultilangString from '../../../components/plain/FieldList_MultilangString';

class TabBrPhysChar extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<Label title={'Тип носія'} style={{marginRight: '10px'}}/>
					<FieldEnum instance={instance} path={'/physChar/media'} plain={plain}
						styleSelect={{flexGrow: '1', marginRight: '20px'}}
					/>
					<Label title={'Формат носія'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/mediaFormat'} plain={plain}
						styleInput={{flexGrow: '1'}}
					 />
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
					<Label title={'Кіл-ть сторінок'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/volume'} plain={plain}
						styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
					 />
					<Label title={'Видавничих аркушів'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/pubSheets'} plain={plain}
						styleInput={{width: '60px', flexGrow: '1', marginRight: '20px'}}
					 />
					<Label title={'Друкованих аркушів'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/printSheets'} plain={plain}
						styleInput={{width: '60px', flexGrow: '1'}}
					 />
				</div>
				<div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
					<Label title={'Висота (мм)'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/height'} plain={plain}
						styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
					 />
					<Label title={'Ширина (мм)'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/width'} plain={plain}
						styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
					 />
					<Label title={'Товщина (мм)'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/thickness'} plain={plain}
						styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
					 />
					<Label title={'Вага (г)'} style={{marginRight: '10px'}}/>
					<FieldString instance={instance} path={'/physChar/weight'} plain={plain}
						styleInput={{width: '100px', flexGrow: '1'}}
					 />
				</div>
				<div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
					<Label title={'Тип друку'} style={{marginRight: '10px'}}/>
					<FieldEnum instance={instance} path={'/physChar/printType'} plain={plain}
						styleSelect={{flexGrow: '1', marginRight: '20px'}}
					/>
					<Label title={'Обкладинка'} style={{marginRight: '10px'}}/>
					<FieldEnum instance={instance} path={'/physChar/cover'} plain={plain}
						styleSelect={{flexGrow: '1', marginRight: '20px'}}
					/>
					<Label title={'Скріплення'} style={{marginRight: '10px'}}/>
					<FieldEnum instance={instance} path={'/physChar/clip'} plain={plain}
						styleSelect={{flexGrow: '1'}}
					/>
				</div>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<FieldList_Enum title={'Уточнення форми видання'}
						instance={instance} path={'/physChar/productFormDetail'} plain={plain}
						labelEmptyToHeader={true}
						table={{width: '100%', styles: {}}}
						styles={{divMain: {flexGrow: '0', marginRight: '10px'}}}
					/>
					<FieldList_MultilangString title={'Інші відомості'}
						instance={instance} path={'/physChar/other'} plain={plain}
						labelEmptyToHeader={true}
						table={{width: '100%', styles: {}}}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
					<FieldList_MultilangString title={'Додатки'}
						instance={instance} path={'/physChar/appendix'} plain={plain}
						labelEmptyToHeader={true}
						table={{width: '100%', styles: {}}}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
				</div>
				<FieldList_MultilangString title={'Примітки'}
					instance={instance} path={'/physChar/comment'} plain={plain}
					labelEmptyToHeader={true}
					table={{width: '100%', styles: {}}}
					styles={{divMain: {flexGrow: '1', marginTop: '10px'}}}
				/>
			</div>
		)
	}
}

export default TabBrPhysChar; 
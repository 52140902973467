import React from 'react';

import Label from '../../../components/plain/Label';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_CardsPanel from '../../../components/plain/FieldList_CardsPanel';

class TabBrNames extends React.Component {
	
	constructor(props) {
		super(props);
	}

	titleCardBuilder = (fieldTitle, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var typeText = plain.realm.getEnumerationItemText( plain.enumTitleType, fieldTitle.value.titleType.value);
		if( typeText )
			card += typeText + '; ';
		
		var langText = plain.realm.getEnumerationItemText( plain.enumLanguage, fieldTitle.value.language.value);
		if( langText )
			card += langText + '; ';
		
		card += fieldTitle.value.mainName.value;
		return card;
	}
	
	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{padding: '10px' }}>
				<FieldList_CardsPanel path={'/title'} instance={instance} plain={plain} 
					itemComponent={TitlePanel} itemCardBuilder={this.titleCardBuilder}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '500px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGray',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		);
	}
}

class TitlePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Тип'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'titleType'} parentField={fieldGroup} plain={plain}
							styleSelect={{marginRight: '30px', flexGrow: '1'}}
						/>
						<Label title={'Мова'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Серія'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'series'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Назва'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'mainName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Скорочена назва'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'shortName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<Label title={'Шрифт заголовка'} style={{marginRight: '10px'}}/>
						<FieldEnum instance={instance} path={'scriptOfTitle'} parentField={fieldGroup} plain={plain} />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Відомості щодо назви'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'subName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Том або рік'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'volYear'} parentField={fieldGroup} plain={plain}
							styleInput={{width: '100px', marginRight: '30px'}}
						 />
						<Label title={'Назва тома'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'volName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Номер частини'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'partNum'} parentField={fieldGroup} plain={plain}
							styleInput={{width: '100px', marginRight: '30px'}}
						 />
						<Label title={'Назва частини'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'partName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Версія'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'version'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<Label title={'Період'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'period'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Дані каталогізатора'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'nameInfo'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Інші відомості'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'other'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Примітки'} style={{marginRight: '10px'}}/>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrNames;